import React, { Fragment, useEffect, useState } from "react";
import styled from '@emotion/styled'
import 'sortable-tablesort/sortable.min.css'
import 'sortable-tablesort/sortable.min.js'

import "../App.css";
import { generateNodeQuery, generateWayQuery } from "../utils/overpass-queries";
import { overpassTurboRequest } from "../utils/overpass";
import { OSMNode, OSMWay } from "../types";

const PageTitle = styled.h1`
  font-weight: bold;
  line-height: 0.8;
  display: block;
  font-family: "Helvetica Neue", Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-left: -15px;
  margin-top: 15px;
  margin-bottom: 0px;
  color: black;
  text-transform: uppercase;

  font-size: 70px;

  @media (max-width: 500px) {
    font-size: 50px;
  }
`;
const PageHeading = styled.h2`
    text-transform: uppercase;
  `;

const HeadingBylineContainer = styled.div`
  max-width: 800px;
`;

const GreyTextContainer = styled.div`
  max-width: 800px;
  opacity: 0.8;
`;

interface OsmObjectPackage {
  id: number,
  type: 'way' | 'node',
  name: string,
}

const osmIds: Record<string, OsmObjectPackage> = {
  // Eg.
  // https://www.openstreetmap.org/way/1094427573
  eastWestCyclewayWayId: {
    id: 1094427573,
    type: 'way',
    name: 'Shared path past playing fields (to Anzac bridge)'
  },
  greenLinkBridgeWayId: {
    id: 959658338,
    type: 'way',
    name: 'Green Link Bridge shared path'
  },
  westernMainCycleway: {
    id: 1095835207,
    type: 'way',
    name: 'Shared path past pond (to Lilyfield road)'
  },
  helixRamp: {
    id: 942837440,
    name: 'Whites Creek Link helix ramp',
    type: 'way'
  },
  southPlayground: {
    id: 1231226196,
    type: 'way',
    name: 'South playground'
  },
  soccorPitch: {
    id: 1191538983,
    type: 'way',
    name: 'Soccer pitch'
  },
  cricketPitch: {
    id: 1191538984,
    type: 'way',
    name: 'Cricket pitch'
  },
  northFitnessStation: {
    id: 11421651358,
    type: 'node',
    name: 'North fitness station'
  }
}

// Make a type based on the keys of the osmIds object
type WaySlugs = keyof typeof osmIds;

async function isAccessAllowedOnObject(wayId: number, type: 'way' | 'node'): Promise<boolean> {
  const query = type === 'way' ? generateWayQuery(wayId) : generateNodeQuery(wayId);
  console.log(`query for ${wayId}: ${query}`)
  const resultEastWest = await overpassTurboRequest(query) as (OSMWay | OSMNode)[];
  if (resultEastWest.length !== 1) {
    throw new Error(`Expected 1 result, got ${resultEastWest.length}`)
  }
  const tags = resultEastWest[0].tags;
  const access = tags.access;
  if (access !== 'no') {
    return true;
  }
  return false;
}

// React component
function LoadingOrOpenClosed({ osmPackage }: { osmPackage: OsmObjectPackage }) {
  const [result, setResult] = useState<boolean | undefined>(undefined);
  const fetchData = async () => {
    const val = await isAccessAllowedOnObject(osmPackage.id, osmPackage.type);
    setResult(val);
  };
  useEffect(() => {
    fetchData();
  })
  if (result === undefined) {
    return <span>loading...</span>
  }
  return <span
    style={{
      color: result ? 'green' : 'red'
    }}

  >{result ? 'open' : 'closed'}</span>;
}

export const IndexPageComponent = () => {
  return (
    <Fragment>
      <h1>Is Rozelle Parklands Open Yet?</h1>
      <p>See the latest <a 
        target="_blank"
        rel="noopener noreferrer"
      href="https://www.transport.nsw.gov.au/news-and-events/media-releases/work-to-remediate-rozelle-parklands-set-to-start-tomorrow">
        media release (Work to remediate Rozelle Parklands set to start tomorrow - Jan 2024)
        </a>:
        <p>"...Up to 30 workers will be on site over coming weeks to methodically work through the park to ensure full compliance with the EPA’s <b>February 29</b> deadline to remove affected material at the parklands site."</p>
        </p>
        "This process adds additional time to the completion of the work on site, with a staged approach allowing the first sections of the park to open gradually in <b>March</b>, with the majority of publicly accessible areas of the parkland expected to be open to the public during <b>April</b>."
        <p>
          <p>"...Walking and bicycle paths through the park will be closed during the clean-up work..."</p>

        </p>
      <div>
        <GreyTextContainer>
          {/* Get current date as ISO 8601 in local timezone (not just removing T) and include up to minutes: */}
          <p>According to OpenStreetMap as of {new Date().toString()}:</p>

          {
            // Map over keys in result, and for each make an h2 in the style of Way name (looked up in names): yes or no
            Object.entries(osmIds).map(([rawKey, value]) => {
              const key: WaySlugs = rawKey as WaySlugs;
              const osmPackage: OsmObjectPackage = osmIds[key];
              return (
                <h2>
                  {<a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.openstreetmap.org/${osmPackage.type}/${osmPackage.id}`}>{osmPackage.name}
                  </a>}:{" "}

                  {/* Add green to span when value is true, red when false */}
                  <LoadingOrOpenClosed osmPackage={osmPackage} />
                </h2>
              )
            })

          }

        </GreyTextContainer>

        <h2>Bicycle navigation during disruption</h2>
        <p>If you want smartphone navigation directions for cycling around the park, see blog post <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://jakecoppinger.com/2020/07/the-best-apps-for-bicycle-directions/">
          The Best Apps for Bicycle Directions (2020)</a>. Routing apps may use data that is a week or two old.</p>

          <h2>If you find an error</h2>
          <p>Contact <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://jakecoppinger.com"
          >
            Jake Coppinger</a> (via <a href="mailto:jake@jakecoppinger.com">email</a>,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://mastodon.social/@jakecoppinger"
            >Mastodon</a>),
            edit OSM yourself at <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://openstreetmap.org">openstreetmap.org</a>
            {" "}or find a volunteer in your area to update it at <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://wiki.openstreetmap.org/wiki/Australian_Mapping_Community">wiki.openstreetmap.org/wiki/Australian_Mapping_Community</a>
            .</p>

          <p>
            <b>Author:</b> This is a side project by{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://jakecoppinger.com"
            >
              Jake Coppinger
            </a> and will be open source on Github. </p>
      </div>
    </Fragment>
  );
};

function hydrateQueryString(query: string, relationId: number): string {
  const magicRelationNumber = 999999;
  // Replace instances of the magic number in the query with the relationId
  return query.replace(magicRelationNumber.toString(), relationId.toString());
}

function generateQueryOrUndefined(queryName: string, relationId: number, overpassQueryStrings: Record<string, string>): string | undefined {
  return overpassQueryStrings[queryName] ? hydrateQueryString(overpassQueryStrings[queryName], relationId) : undefined
}


function formatLengthInKm(lengthInMetres: number): string {
  return `${(lengthInMetres / 1000).toFixed(2)}`;
}

function formatRatio(ratio: number | null): string {
  if (ratio === null) {
    return "N/A";
  }
  return `${(ratio * 100).toFixed(1)}%`;
}

/** Takes an area in square metres and formats it in square kilometres  */
function formatArea(areaInSquareMetres: number): string {
  return `${(areaInSquareMetres / 1000000).toFixed(1)} km²`;
}
/** Links to an opensreetmap relation, and renders children */
function RelationLink({
  relationId,
  children,
}: {
  relationId: number;
  children: React.ReactNode;
}) {
  const lookup: Record<string, string> = { 'Centrum': 'Amsterdam (Centrum)' }
  const name = lookup[children as string] || children as string
  return (
    <a
      href={`https://openstreetmap.org/relation/${relationId}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {name}
    </a>
  );
}

function WikipediaLink({
  articleName,
  children,
}: {
  articleName: string;
  children: React.ReactNode;
}) {
  return (
    <a
      href={`https://en.wikipedia.org/wiki/${articleName}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </a>
  );
}

/**
 * Format a population number
 * Adds a thousands separator.
 */
function formatPopulation(population: number | null): string {
  if (population === null) {
    return "(no data)";
  }
  // Add thousands separator to population number and returns it as a string
  return population.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
