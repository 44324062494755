export const generateWayQuery = (wayId: number) => `
[out:json][timeout:25];
way(${wayId});
out tags;
`;

export const generateNodeQuery = (wayId: number) => `
[out:json][timeout:25];
node(${wayId});
out tags;
`;
